import BaseInstance from "../base.instance";
import { SIMPEG_MUTASI_JABATAN_UNIT } from "../constants";

const MutationService = {
  saveJobUnit(data) {
    return BaseInstance.post(SIMPEG_MUTASI_JABATAN_UNIT, data);
  }
};

export default MutationService;
