<template>
  <v-dialog v-model="visible" persistent max-width="1200">
    <v-card class="pa-5 rounded-lg" id="card-custom">
      <v-btn @click="visible = false" x-small icon class="float-right">
        <v-icon>close</v-icon>
      </v-btn>
      <v-card-text class="subtitle-2 pt-0 font-weight-bold">
        Mutasi Jabatan dan Unit Kerja Pegawai
      </v-card-text>
      <v-divider class="mb-5" />
      <v-form
        id="form"
        ref="form"
        v-model="valid"
        lazy-validation
        @submit.prevent="save()"
      >
        <v-row>
          <v-col cols="6" class="pa-0">
            <v-row>
              <v-col cols="3" class="py-1">
                <v-subheader class="caption headline-color font-weight-regular"
                  >NIP</v-subheader
                >
              </v-col>
              <v-col cols="9" class="py-1">
                <v-text-field
                  v-model="detail.NIP"
                  hide-details
                  disabled
                  outlined
                  dense
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="3" class="py-1">
                <v-subheader class="caption headline-color font-weight-regular"
                  >Nama</v-subheader
                >
              </v-col>
              <v-col cols="9" class="py-1">
                <v-text-field
                  v-model="detail.nama"
                  hide-details
                  disabled
                  outlined
                  dense
                ></v-text-field>
              </v-col>
            </v-row>
          </v-col>
          <v-col cols="6" class="pa-0">
            <v-card-actions class="mt-3">
              <v-spacer />
              <v-btn
                small
                outlined
                color="#717171"
                class="px-12"
                @click="visible = false"
                >Batal</v-btn
              >
              <slot name="action">
                <v-btn
                  small
                  color="#FBB005"
                  class="px-12 subtitle-2 text-capitalize"
                  :loading="formLoading"
                  type="submit"
                  :disabled="!valid"
                  form="form"
                  >Simpan</v-btn
                >
              </slot>
            </v-card-actions>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" xl="5" lg="5" md="5" sm="12" xs="12" class="pa-1">
            <v-card outlined class="pa-5 mt-2">
              <p class="caption pt-0 font-weight-bold">Unit Kerja Baru</p>
              <v-row>
                <v-col cols="12" class="py-1">
                  <p class="mb-1 caption">
                    {{
                      !$_.isEmpty(detail.unit_utama_nama)
                        ? detail.unit_utama_nama
                        : "-"
                    }}
                  </p>
                  <v-autocomplete
                    :disabled="unitDisabled || disabled"
                    :items="unitList"
                    :loading="loadingUnit"
                    outlined
                    dense
                    v-model="form.unit_utama"
                    item-text="singkatan"
                    item-value="unit_utama_id"
                    @change="changeUnit(1)"
                    @click:clear="changeUnit(1)"
                  ></v-autocomplete>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12" class="py-1">
                  <p class="mb-1 caption">
                    {{
                      !$_.isEmpty(detail.unit_kerja_2_nama)
                        ? detail.unit_kerja_2_nama
                        : "-"
                    }}
                  </p>
                  <v-autocomplete
                    :disabled="unitDisabled || disabled"
                    :items="unit2List"
                    :loading="loadingUnit"
                    outlined
                    dense
                    v-model="form.unit_kerja_2"
                    item-text="singkatan"
                    item-value="unit_kerja_2_id"
                    @change="changeUnit(2)"
                    @click:clear="changeUnit(2)"
                  ></v-autocomplete>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12" class="py-1">
                  <p class="mb-1 caption">
                    {{
                      !$_.isEmpty(detail.unit_kerja_3_nama)
                        ? detail.unit_kerja_3_nama
                        : "-"
                    }}
                  </p>
                  <v-autocomplete
                    :disabled="unitDisabled || disabled"
                    :items="unit3List"
                    :loading="loadingUnit"
                    outlined
                    dense
                    v-model="form.unit_kerja_3"
                    item-text="singkatan"
                    item-value="unit_kerja_3_id"
                    @change="changeUnit(3)"
                    @click:clear="changeUnit(3)"
                  ></v-autocomplete>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12" class="py-1">
                  <p class="mb-1 caption">
                    {{
                      !$_.isEmpty(detail.unit_kerja_4_nama)
                        ? detail.unit_kerja_4_nama
                        : "-"
                    }}
                  </p>
                  <v-autocomplete
                    :disabled="unitDisabled || disabled"
                    :items="unit4List"
                    :loading="loadingUnit"
                    outlined
                    dense
                    v-model="form.unit_kerja_4"
                    item-text="singkatan"
                    item-value="unit_kerja_4_id"
                    @change="changeUnit(4)"
                    @click:clear="changeUnit(4)"
                  ></v-autocomplete>
                </v-col>
              </v-row>
              <v-btn
                :color="unitDisabled ? '#E4E4E4' : '#297BBF'"
                class="mt-2 caption text-capitalize white--text"
                @click="getCurrentUnit"
                :disabled="unitDisabled || disabled"
                >Ambil Unit Kerja Saat Ini</v-btn
              >
            </v-card>
          </v-col>
          <v-col cols="12" xl="7" lg="7" md="7" sm="12" xs="12" class="pa-1">
            <v-card outlined class="pa-5 mt-2">
              <p class="caption pt-0 font-weight-bold">
                Jabatan dan Eselon Baru
              </p>
              <v-row>
                <v-col cols="2" class="py-6">
                  <v-subheader
                    class="caption headline-color font-weight-regular"
                    >Jabatan</v-subheader
                  >
                </v-col>
                <v-col cols="10" class="py-1">
                  <p class="mb-1 caption">
                    {{
                      !$_.isEmpty(detail.jabatan_nama)
                        ? detail.jabatan_nama
                        : "-"
                    }}
                  </p>
                  <v-autocomplete
                    :disabled="disabled"
                    :items="jabatanList"
                    :loading="loading_jabatan"
                    outlined
                    dense
                    v-model="form.jabatan"
                    item-text="nama_jabatan"
                    item-value="jabatan_id"
                  ></v-autocomplete>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="2" class="py-6">
                  <v-subheader
                    class="caption headline-color font-weight-regular"
                    >Eselon</v-subheader
                  >
                </v-col>
                <v-col cols="4" class="py-1">
                  <p class="mb-1 caption">
                    {{
                      !$_.isEmpty(detail.eselon_nama) ? detail.eselon_nama : "-"
                    }}
                  </p>
                  <v-autocomplete
                    :disabled="disabled"
                    :items="eselonList"
                    :loading="loading_eselon"
                    outlined
                    dense
                    v-model="form.eselon"
                    item-text="keterangan"
                    item-value="eselon_id"
                  ></v-autocomplete>
                </v-col>
                <v-col cols="2" class="py-6">
                  <v-subheader
                    class="caption headline-color font-weight-regular"
                    >Kelas (Grade)</v-subheader
                  >
                </v-col>
                <v-col cols="4" class="py-1">
                  <p class="mb-1 caption">
                    {{ detail.kelas ? detail.kelas : "-" }}
                  </p>
                  <v-autocomplete
                    :disabled="disabled"
                    :items="kelasList"
                    :loading="loading_kelas"
                    outlined
                    dense
                    v-model="form.kelas"
                    item-text="kelas_jabatan"
                    item-value="kelas_jabatan"
                  ></v-autocomplete>
                </v-col>
              </v-row>
              <p class="caption ma-0 text-right">Kuota Jabatan: 0 Pegawai</p>
              <p class="caption ma-0 text-right">Telah Digunakan: 0 Pegawai</p>
              <v-row>
                <v-col cols="2" class="py-1">
                  <v-subheader
                    class="caption headline-color font-weight-regular"
                    >TMT</v-subheader
                  >
                </v-col>
                <v-col cols="10" class="py-1">
                  <p class="mb-1 caption">
                    {{
                      !$_.isEmpty(detail.tgl_mulai_PNS)
                        ? detail.tgl_mulai_PNS
                        : "-"
                    }}
                  </p>
                  <v-text-field
                    :disabled="disabled"
                    class="mt-1"
                    v-model="form.tgl_mulai_PNS"
                    type="date"
                    outlined
                    dense
                    :rules="[v => !!v || 'TMT harus diisi']"
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="2" class="py-1">
                  <v-subheader
                    class="caption headline-color font-weight-regular"
                    >No SK</v-subheader
                  >
                </v-col>
                <v-col cols="4" class="py-1">
                  <p class="mb-1 caption">
                    {{ !$_.isEmpty(detail.no_sk_PNS) ? detail.no_sk_PNS : "-" }}
                  </p>
                  <v-text-field
                    :disabled="disabled"
                    class="mt-1"
                    v-model="form.no_sk_PNS"
                    outlined
                    dense
                    :rules="[v => !!v || 'Nomor SK harus diisi']"
                  ></v-text-field>
                </v-col>
                <v-col cols="2" class="py-1">
                  <v-subheader
                    class="caption headline-color font-weight-regular"
                    >Tgl SK</v-subheader
                  >
                </v-col>
                <v-col cols="4" class="py-1">
                  <p class="mb-1 caption">
                    {{
                      !$_.isEmpty(detail.tgl_sk_PNS) ? detail.tgl_sk_PNS : "-"
                    }}
                  </p>
                  <v-text-field
                    :disabled="disabled"
                    class="mt-1"
                    v-model="form.tgl_sk_PNS"
                    type="date"
                    outlined
                    dense
                    :rules="[v => !!v || 'Tanggal SK harus diisi']"
                  ></v-text-field>
                </v-col>
              </v-row>
            </v-card>
          </v-col>
        </v-row>
        <v-row v-if="form.attachment_url">
          <v-col cols="3" class="py-1">
            <v-subheader class="caption headline-color font-weight-regular"
              >Attachment</v-subheader
            >
          </v-col>
          <v-col cols="9" class="py-3">
            <a
              class="hover-primary caption"
              :href="form.attachment_url"
              target="_blank"
              >Lihat</a
            >
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" class="py-1">
            <FileDraggable ref="fileDraggable" @on-change="handleOnChange" />
          </v-col>
        </v-row>
      </v-form>
    </v-card>
  </v-dialog>
</template>
<script>
import PublicService from "@/services/resources/public.service";
import DataUtamaService from "@/services/resources/data.utama.service";
import MutationService from "@/services/resources/mutation.service";

const FileDraggable = () => import("@/components/File/Draggable");

export default {
  components: {
    FileDraggable
  },
  props: {
    unitDisabled: { type: Boolean, required: false, default: false },
    disabled: { type: Boolean, required: false, default: false }
  },
  data() {
    return {
      valid: false,
      visible: false,
      formLoading: false,
      loading_jabatan: false,
      loading_eselon: false,
      loading_kelas: false,
      loading_unitutama: false,
      loadingUnit: false,
      unitutamaList: [],
      jabatanList: [],
      eselonList: [],
      kelasList: [],
      unitList: [],
      unit2List: [],
      unit3List: [],
      unit4List: [],
      detail: {
        NIP: null,
        nama: null,
        unit_utama: null,
        unit_utama_nama: null,
        unit_kerja_2: null,
        unit_kerja_2_nama: null,
        unit_kerja_3: null,
        unit_kerja_3_nama: null,
        unit_kerja_4: null,
        unit_kerja_4_nama: null,
        jabatan: null,
        jabatan_nama: null,
        eselon: null,
        eselon_nama: null,
        kelas: null,
        tgl_mulai_PNS: null,
        no_sk_PNS: null,
        tgl_sk_PNS: null,
        attachment_url: null
      },
      form: {
        unit_utama: null,
        unit_kerja_2: null,
        unit_kerja_3: null,
        unit_kerja_4: null,
        jabatan: null,
        eselon: null,
        kelas: null,
        tgl_mulai_PNS: null,
        no_sk_PNS: null,
        tgl_sk_PNS: null,
        attachment: null,
        attachment_url: null
      }
    };
  },
  watch: {
    jabatanForm() {
      this.getEselon();
    },
    visible(val) {
      if (!val) {
        this.form.attachment = null;
        this.form.attachment_url = null;
        this.$refs.fileDraggable.removeFile();
        this.$refs.fileDraggable.closeAlert();
        this.$refs.form.reset();
      }
    }
  },
  computed: {
    jabatanForm() {
      return this.form.jabatan;
    }
  },
  methods: {
    // Triggered in parent
    populate(item) {
      this.detail = this.$_.merge(this.detail, item);
      this.visible = true;
      if (this.unitDisabled) {
        this.getCurrentUnit();
      }
    },
    populateUpdate(item, form) {
      this.detail = this.$_.merge(this.detail, item);
      this.form = this.$_.merge(this.form, form);
      this.visible = true;
      this.populateUnit();
    },
    change(val) {
      this.visible = val;
    },
    // Common Function
    getCurrentUnit() {
      let unit_kerja = {
        unit_utama: this.detail.unit_utama,
        unit_kerja_2: this.detail.unit_kerja_2,
        unit_kerja_3: this.detail.unit_kerja_3,
        unit_kerja_4: this.detail.unit_kerja_4
      };
      this.form = this.$_.merge(this.form, unit_kerja);
      this.populateUnit();
    },
    changeUnit(type = 2) {
      if (type == 1) {
        this.unit2List = this.unitList.find(
          d => d.unit_utama_id == this.form.unit_utama
        )?.unit_kerja2;
        this.form.unit_kerja_2 = null;
        this.form.unit_kerja_3 = null;
        this.form.unit_kerja_4 = null;
      } else if (type == 2) {
        this.unit3List = this.unit2List.find(
          d => d.unit_kerja_2_id == this.form.unit_kerja_2
        )?.unit_kerja3;
        this.form.unit_kerja_3 = null;
        this.form.unit_kerja_4 = null;
      } else if (type == 3) {
        this.unit4List = this.unit3List.find(
          d => d.unit_kerja_3_id == this.form.unit_kerja_3
        )?.unit_kerja4;
        this.form.unit_kerja_4 = null;
      }
    },
    populateUnit() {
      this.unit2List = this.unitList.find(
        d => d.unit_utama_id == this.form.unit_utama
      )?.unit_kerja2;
      this.unit3List = this.unit2List.find(
        d => d.unit_kerja_2_id == this.form.unit_kerja_2
      )?.unit_kerja3;
      this.unit4List = this.unit3List.find(
        d => d.unit_kerja_3_id == this.form.unit_kerja_3
      )?.unit_kerja4;
    },
    handleOnChange(val) {
      this.form.attachment = val;
    },
    save() {
      if (this.$refs.form.validate()) {
        if (!this.form.attachment_url && !this.form.attachment) {
          this.$refs.fileDraggable.openAlert("File harus diisi", "error");
          return;
        }
        const formData = new FormData();
        formData.append("NIP", this.detail.NIP);
        formData.append("jabatan_id", this.form.jabatan);
        formData.append("eselon_id", this.form.eselon);
        formData.append("kelas_jabatan", this.form.kelas);
        formData.append("unit_utama_id", this.form.unit_utama);
        formData.append("unit_kerja_2_id", this.form.unit_kerja_2);
        formData.append("unit_kerja_3_id", this.form.unit_kerja_3);
        formData.append("unit_kerja_4_id", this.form.unit_kerja_4);
        formData.append("tmt", this.form.tgl_mulai_PNS);
        formData.append("no_sk", this.form.no_sk_PNS);
        formData.append("tgl_sk", this.form.tgl_sk_PNS);
        formData.append("attachment", this.form.attachment);
        this.saveJobUnit(formData);
      }
    },
    // Service
    async getLov(type) {
      try {
        this[`loading_${type}`] = true;
        await PublicService[`${type}GetList`]({
          search: ""
        })
          .then(response => {
            const { status, data } = response.data;
            if (status) {
              this[`${type}List`] = data;
            }
          })
          .catch(error => {
            throw new Error(error);
          })
          .finally(() => (this[`loading_${type}`] = false));
      } catch (err) {
        console.error(err);
      }
    },
    async getListUnit() {
      try {
        this.loadingUnit = true;
        await DataUtamaService.getUnitKerjaAll({
          platform: this.isPresensi ? "presensi" : "simpeg"
        })
          .then(response => {
            const { status, data } = response.data;
            if (status) {
              this.unitList = data;
              this.populateUnit();
            }
          })
          .catch(error => {
            throw new Error(error);
          })
          .finally(() => (this.loadingUnit = false));
      } catch (err) {
        console.error(err);
      }
    },
    async getEselon() {
      try {
        this.loading_eselon = true;
        await DataUtamaService.getEselonByJabatan(this.form.jabatan || "")
          .then(response => {
            const { status, data } = response.data;
            if (status) {
              this.eselonList = data;
            }
          })
          .catch(error => {
            throw new Error(error);
          })
          .finally(() => (this.loading_eselon = false));
      } catch (err) {
        console.error(err);
      }
    },
    async saveJobUnit(data) {
      try {
        this.formLoading = true;
        await MutationService.saveJobUnit(data)
          .then(response => {
            const { status, message } = response.data;
            if (status) {
              this.$emit("on-refresh");
              this.visible = false;
              this.$store.commit("snackbar/setSnack", {
                show: true,
                message: message,
                color: "success"
              });
            } else {
              this.$store.commit("snackbar/setSnack", {
                show: true,
                message: message,
                color: "error"
              });
            }
          })
          .catch(error => {
            throw new Error(error);
          })
          .finally(() => {
            this.formLoading = false;
          });
      } catch (err) {
        console.error(err);
      }
    }
  },
  mounted() {
    this.getLov("jabatan");
    this.getLov("kelas");
    this.getEselon();
    this.getListUnit();
  }
};
</script>
